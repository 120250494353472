<template>
  <div>
    <v-card key="registeredBundlesList">
      <v-toolbar>
        <v-card-title>Registered bundles</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'Superadmin') || $can('create', 'registry')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          to="/store/bundle/registry/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <DataTableExtended
          :headers="headers"
          :items="ordersRegisteredList"
          item-key="_key"
          class="elevation-0 border"
          :calculate-widths="true"
          :loading="showTableLoader"
          :loading-text="showTableLoader ? 'Loading…' : 'No data available'"
          sortable
          :multi-sort="false"
          :server-items-length="ordersRegisteredCount"
          v-on:init-table-data="getOrdersRegisteredList"
          ref="refTableRegisteredBundles"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="px-3">
              <!--              <v-col cols="12" md="6" lg="4">-->
              <!--                <v-text-field-->
              <!--                  outlined-->
              <!--                  v-model="search"-->
              <!--                  append-icon="mdi-magnify"-->
              <!--                  label="Search"-->
              <!--                  single-line-->
              <!--                  hide-details-->
              <!--                  clearable-->
              <!--                ></v-text-field>-->
              <!--              </v-col>-->

              <v-col cols="12" md="4" lg="3">
                <SelectBundlesAsync
                  v-model="filterByBundle"
                  outlined
                  label="Filter by bundle"
                  :multiple="false"
                  item-value="_key"
                  item-text="name"
                  clearable
                  field="bundle"
                  :menu-props="{ maxHeight: 304 }"
                />
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <SelectEnginesAsync
                  v-model="filterByEngine"
                  outlined
                  label="Filter by engine"
                  :multiple="false"
                  item-value="_key"
                  item-text="name"
                  clearable
                  field="engine"
                  :menu-props="{ maxHeight: 304 }"
                />
              </v-col>

              <v-col cols="12" md="4" lg="3" v-if="isSuperUser">
                <SelectCompaniesAsync
                  v-model="filterByCompany"
                  outlined
                  label="Filter by company"
                  :multiple="false"
                  item-value="_key"
                  item-text="name"
                  field="group_key"
                  :menu-props="{ maxHeight: 304 }"
                  clearable
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>

          <template v-slot:[`item.bundle.name`]="{ item }">
            <div>
              {{ item.bundle ? item.bundle.name : "" }}
            </div>
          </template>
          <template v-slot:[`item.engine.name`]="{ item }">
            <div>
              {{ item.engine.name }}
            </div>
          </template>
          <template v-slot:[`item.expiration_date`]="{ item }">
            <div>
              {{ item.expiration_date | dateFormat }}
            </div>
          </template>
          <template v-slot:[`item.white_list`]="{ item }">
            <template v-if="item.white_list && item.white_list.length > 0">
              <div v-for="(ip, index) in item.white_list" :key="'ip-' + index">
                <span class="font-weight-regular">{{ ip }}</span>
              </div>
            </template>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu transition="slide-y-transition" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  :to="`/store/bundle/registry/edit/${item._key}`"
                  v-if="
                    $can('update', 'Superadmin') || $can('update', 'registry')
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >Edit registered bundle</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit"
                      >mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  @click="deleteOrderRegistered(item)"
                  v-if="
                    $can('delete', 'Superadmin') || $can('delete', 'registry')
                  "
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >Delete registered bundle</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="!$vuetify.breakpoint.smAndUp"
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  @click.native="edit(item)"
                  v-if="
                    $can('update', 'Superadmin') || $can('update', 'registry')
                  "
                  icon="mdi-pencil"
                  text="Edit registered bundle"
                  :color="Pallette.actionButtons.edit"
                />
                <VerticalButton
                  @click.native="deleteOrderRegistered(item)"
                  v-if="
                    $can('delete', 'Superadmin') || $can('delete', 'registry')
                  "
                  icon="mdi-delete"
                  text="Delete registered bundle"
                  :color="Pallette.actionButtons.delete"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
/**
 * Component for list of registered bundles to show only for superadmin
 */
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
import VerticalButton from "@/components/buttons/VerticalButton"
import SortMobile from "@/components/table/SortMobile"
import SelectEnginesAsync from "@//components/selects/SelectEnginesAsync"
import SelectBundlesAsync from "@//components/selects/SelectBundlesAsync"
import SelectCompaniesAsync from "@/components/selects/SelectCompaniesAsync"
import DataTableExtended from "@/components/table/DataTableExtended.vue"

export default {
  components: {
    VerticalButton,
    Confirm,
    SortMobile,
    SelectEnginesAsync,
    SelectBundlesAsync,
    SelectCompaniesAsync,
    DataTableExtended,
  },

  data() {
    return {
      headers: [
        {
          text: "Bundle",
          align: "left",
          value: "bundle.name",
          sortable: false,
          width: 220,
        },
        {
          text: "Engine",
          align: "left",
          value: "engine.name",
          sortable: false,
        },
        {
          text: "Expiration date",
          value: "expiration_date",
          width: 170,
          sortable: false,
        },
        {
          text: "IPs",
          value: "white_list",
          width: 150,
          sortable: true,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: 50,
        },
      ],
      tableActions: ["menu"],
      keep: "_key,bundle_key,engine_key,white_list",
      filterByCompany: null,
    }
  },

  watch: {
    filterByEngine: function () {
      this.getOrdersRegisteredList()
    },
    filterByBundle: function () {
      this.getOrdersRegisteredList()
    },
    filterByCompany: function () {
      this.getOrdersRegisteredList()
    },
  },

  methods: {
    getOrdersRegisteredList: function () {
      let self = this
      let getParams =
        self.$refs.refTableRegisteredBundles.getTableServerParams()
      if (self.filterByEngine) getParams.engine_key = self.filterByEngine
      if (self.filterByBundle) getParams.bundle_key = self.filterByBundle
      if (self.filterByCompany && self.isSuperUser)
        getParams.group_key = self.filterByCompany
      if (!self.isSuperUser) getParams.group_key = self.currentUser.group_key
      self.$store.dispatch("getOrdersRegisteredList", { params: getParams })
    },
    deleteOrderRegistered(item) {
      let self = this
      self.$refs.confirmDialog
        .open(
          "Delete",
          "Are you sure you want to delete this registered bundle?",
          { color: "red darken-4" }
        )
        .then((confirm) => {
          if (confirm) {
            self.$store
              .dispatch("deleteOrderRegistered", item)
              .then((response) => {
                if (response.data.status === "success")
                  self.getOrdersRegisteredList()
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
    },
  },

  computed: {
    ...mapGetters({
      ordersRegisteredList: "ordersRegisteredList",
      ordersRegisteredCount: "ordersRegisteredCount",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
    }),

    filterByEngine: {
      get() {
        return this.$store.getters.filterByEngineRegistry
      },
      set(val) {
        this.$store.dispatch("setOrderRegisteredFilters", {
          filterByEngine: val,
        })
      },
    },
    filterByBundle: {
      get() {
        return this.$store.getters.filterByBundleRegistry
      },
      set(val) {
        this.$store.dispatch("setOrderRegisteredFilters", {
          filterByBundle: val,
        })
      },
    },
  },
}
</script>

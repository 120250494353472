<template>
  <div>
    <v-autocomplete
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="filterSearch"
      loading="filterLoading"
      :items="bundlesList"
      :cache-items="true"
      @select="selectValue"
      v-model="val"
    >
      <template v-slot:append-item>
        <div
          v-if="lastPageOfgetEnginesListComputed && !filterSearch"
          v-observe-visibility="{
            callback: visibilityChanged,
          }"
          class="selectPagination"
        >
          <span class="pa-2">Loading more items ...</span>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/**
 * Component of select with list of bundles that are loaded async by chunks, with search
 */
import { mapGetters } from "vuex"

export default {
  name: "SelectBundlesAsync",

  inheritAttrs: false,

  props: ["value"],

  data: () => ({
    filterSearch: null,
    selectPagination: {
      p: 1,
      pp: 20,
    },
  }),

  mounted: function () {
    this.fetchItems()
  },

  watch: {
    selected(val) {
      this.$emit("update:selected", val)
    },
    filterSearch() {
      this.selectPagination.p = 1
      this.fetchItems()
    },
    value: {
      handler: function (val, oldVal) {
        if (val && val !== oldVal) {
          this.getDataForSelected()
        }
      },
      immediate: true,
    },
  },

  methods: {
    visibilityChanged(e) {
      if (this.lastPageOfgetEnginesListComputed) {
        e && this.nextPage()
      }
    },
    startPage() {
      this.selectPagination.p = 1
    },
    nextPage() {
      let maxPages = Math.ceil(this.bundlesCount / this.selectPagination.pp)
      if (this.selectPagination.p <= maxPages - 1) {
        this.filterLoading = true
        this.selectPagination.p += 1
        this.fetchItems()
      }
      this.filterLoading = false
    },
    fetchItems() {
      let fetchData = {
        params: {
          p: this.selectPagination.p,
          pp: this.selectPagination.pp,
          keep: "_key,name",
        },
      }
      if (this.filterSearch) fetchData.params.search = this.filterSearch
      this.$store.dispatch("getBundlesList", fetchData)
    },
    selectValue() {
      this.filterSearch = ""
    },
    getDataForSelected() {
      this.$store.dispatch("bundleSelectedInlist", this.value)
    },
  },

  computed: {
    ...mapGetters({
      bundlesList: "bundlesList",
      bundlesCount: "bundlesCount",
    }),
    lastPageOfgetEnginesListComputed() {
      let flag =
        this.bundlesCount - this.selectPagination.p * this.selectPagination.pp >
        0
      return flag
    },
  },
  val: {
    get() {
      return this.value
    },
    set() {},
  },
}
</script>

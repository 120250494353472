<template>
  <div>
    <BundlesRegisteredList />
  </div>
</template>

<script>
import BundlesRegisteredList from "@/views/store/registry/BundlesRegisteredList"

export default {
  components: {
    BundlesRegisteredList,
  },
}
</script>
